<template>
  <div class="container">
    <div>
      <h1 class="container-title mt-5 text-center"><span v-if='ehCFC'>CFC - </span>  Agendar Atendimento</h1>
    </div>
    <form class="mt-5" @submit.prevent.stop="submit">
      <div class="row">
        <div class="col-12 col-lg-6" v-show="!$store.getters.usuarioPredefinido">
          <input-field
            label="CPF"
            :type="$store.getters.usuarioPredefinido ? 'hidden' : 'text'"
            v-model="formData.cpf"
            :mask="'###.###.###-##'"
            required
            @input="init"
            :validation="$v.formData.cpf"
          />
          <input-field
            label="Nome"
            :type="$store.getters.usuarioPredefinido ? 'hidden' : 'text'"
            required
            v-model.trim="formData.nomeCompleto"
            :validation="$v.formData.nomeCompleto"
          />
          <input-field
            label="Email"
            :type="$store.getters.usuarioPredefinido ? 'hidden' : 'text'"
            required
            v-model.trim="formData.email"
            :validation="$v.formData.email"
          />
        </div>
        <div
          :class="{
            'col-12 col-lg-6': !$store.getters.usuarioPredefinido,
            'col-12 col-lg-6 offset-lg-3': $store.getters.usuarioPredefinido
          }"
        >
          <select-field
            label="Categoria"
            @input="carregaPostos"
            v-show="formData.cpf"
            v-model="formData.idCategoria"
            :items="categorias"
          />
         

          <loading
            font-size="1.2rem"
            text="Carregando Postos..."
            direction="vertical"
            color="#2D52A0"
            :active="isLoadingPostosDisponiveis"
          />

          <template v-if="formData.idCategoria && postosAtendimento.length">
            <template v-if="isCategoriaHabilitacao">
              <alert
                class="alert col-12 mt-2 mb-2"
                v-if="
                  $store.getters.usuarioPredefinido && !podeSelecionarTodosPostos
                "
                type="info"
                :content="
                  `Para este serviço você deve ser atendido por postos de atendimento da ${
                    ciretran ? '' : 'sua'
                  } CIRETRAN ${ciretran ? ciretran : ''}.`
                "
              />
              <alert
                class="alert col-12 mt-2 mb-2"
                v-else-if="!$store.getters.usuarioPredefinido"
                type="info"
                content="Para este serviço o usuário deve ser atendido por postos de atendimento da CIRETRAN que atende a cidade de sua habilitação."
              />
            </template>
            <template v-else-if="formData.idCategoria === 6">
              <alert
                class="alert col-12 mt-2 mb-2"
                v-if="$store.getters.usuarioPredefinido && false"
                type="info"
                content="O documento de habilitação (CNH) deve ser retirado no posto de atendimento que você selecionou no Detran Digital ou onde você fez o processo de habilitação. <br/> O Certificado de Registro de Veículo (CRV) deve ser retirado no posto de atendimento que atende a cidade onde seu veículo foi emplacado."
              />
              <alert
                class="alert col-12 mt-2 mb-2"
                v-else-if="!$store.getters.usuarioPredefinido"
                type="info"
                content="O documento de habilitação (CNH) deve ser retirado no posto de atendimento que o usuário selecionou no Detran Digital ou onde ele fez o processo de habilitação. <br/> O Certificado de Registro de Veículo (CRV) deve ser retirado no posto de atendimento que atende a cidade onde o veículo foi emplacado."
              />
            </template>
            <template v-else>
              <alert
                class="alert col-12 mt-2 mb-2"
                v-if="$store.getters.usuarioPredefinido && false"
                type="info"
                content="Para este serviço você deve ser atendido por postos de atendimento da CIRETRAN da cidade onde o seu veículo é emplacado ou onde deseja fazer o emplacamento."
              />
              <alert
                class="alert col-12 mt-2 mb-2"
                v-else-if="!$store.getters.usuarioPredefinido"
                type="info"
                content="Para este serviço o usuário deve ser atendido por postos de atendimento da CIRETRAN da cidade onde o seu veículo é emplacado ou onde deseja fazer o emplacamento."
              />
            </template>
          </template>

          <div
            class="row"
            v-show="formData.idCategoria"
            :class="{ 'is-loading': isLoadingPostosDisponiveis }"
          >
            <div
              id="bloco-posto-atendimento"
              class="mb-0"
              :class="{
                'col-md-8': usuarioPossuiEnderecos && podeSelecionarTodosPostos,
                'col-12': !(usuarioPossuiEnderecos && podeSelecionarTodosPostos)
              }"
            >
              <select-field
                label="Posto de Atendimento"
                searchable
                @input="carregaServicos"
                v-model="formData.idPostoAtendimento"
                :items="postosAtendimento"
                class="mb-0"
              />
            </div>
            <div class="d-flex col-md-4 mb-sm-4" v-if="usuarioPossuiEnderecos && podeSelecionarTodosPostos">
              <CheckboxItem
                @input="carregaPostos"
                label="Filtrar pelo meu endereço"
                v-model="filtroMeuEndereco"
                :checked="filtroMeuEndereco"
                
              />
            </div>
          </div>
          <p
            class="mb-4"
            v-if="formData.idCategoria && !postosAtendimento.length && !isLoadingPostosDisponiveis"
          >
            Sem postos de atendimento disponíveis para este serviço.
          </p>
          <p
            v-if="
              formData.idCategoria &&
                !postosAtendimento.length &&
                !isLoadingPostosDisponiveis &&
                mensagemCategoriaSemPosto
            "
          >
            {{ mensagemCategoriaSemPosto }}
          </p>
          <select-field
            v-show="formData.idCategoria && formData.idPostoAtendimento"
            label="Serviço"
            @input="buscarDatasDisponiveis"
            v-model="formData.idServico"
            :items="servicos"
          />
        </div>
      </div>

      <div v-if="formData.idServico" class="mt-4">
        <alert
          class="alert col-12 col-lg-6 mt-2"
          type="danger"
          title="Atenção"
          content="Caso a data de seu interesse não esteja disponível para agendamento, você pode comparecer diretamente em uma das unidades do Detran.<br>O atendimento também ocorre por ordem de chegada."
        />
      </div>

      <div v-if="formData.idServico && avisos.length" class="mt-2">
        <alert
          v-for="(aviso, index) in avisos"
          :key="index"
          :class="{ 'col-lg-6': $store.getters.usuarioPredefinido }"
          class="alert col-12 mt-2"
          :title="aviso.titulo"
          :content="aviso.conteudo"
        />
      </div>

      <div class="mt-4">
        <loading
          font-size="1.2rem"
          text="Verificando disponibilidade..."
          direction="vertical"
          color="#2D52A0"
          :active="isLoadingHorarios || isLoadingDatas || isLoadingBloqueadas"
        />

        <div class="row" :class="{ 'is-loading': isLoadingHorarios || isLoadingDatas || isLoadingBloqueadas }">
          <div class="col-12 col-lg-6 offset-lg-3" id="bloco-data-atendimento">
            <div class="form-item  mb-5" v-show="formData.idServico && datas.length">
              <div class="calendar-wrapper">
                <div class="calendar-container">
                  <label class="calendar-label">Data para Atendimento *</label>
                  <vc-date-picker
                    ref="calendario"
                    locale="pt-BR"
                    :first-day-of-week="1"
                    :available-dates="datasDisponiveis"
                    @input="selecionarData"
                    v-model="formData.data"
                    mode="single"
                    nav-visibility="hidden"
                    color="blue"
                    is-inline
                    is-expanded
                    is-required
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="bloco-horario-agendamento col-12 col-lg-6 offset-lg-3">
            <select-field
              v-show="formData.idServico && datas.length && horariosDisponiveis.length"
              label="Horário do Agendamento"
              v-model="formData.horario"
              @input="preAgendar"
              :items="horariosDisponiveis"
            />
          </div>
        </div>
      </div>
      <div
        class="row justify-content-center mt-4"
        :class="{ 'mt-5': !datas.length, 'mb-5': !$store.getters.usuarioPredefinido }"
      >
        <action submit color="primary" filled text="Agendar" :disabled="$v.$invalid" />
      </div>
    </form>
    <div class="actions-container mt-4" v-if="$store.getters.usuarioPredefinido">
      <action
        submit
        color="lightblue"
        outlined
        small
        text="Acompanhar Agendamentos"
        class="btn-acompanhamento mt-lg-4 mt-3"
        :url="{ name: 'acompanhamento' }"
      />
    </div>
    <ModalHorarioAgendado
      :active="modal.active"
      titulo="Atendimento Agendado!"
      :agendamento="agendamentoConfirmado"
      @fechar="limparFormulario"
    >
      Agendamento efetivado com sucesso!
    </ModalHorarioAgendado>
  </div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators'
import { cpf, dataFutura, limiteDeTrintaDias, diaUtil } from '@/validators'
import { format } from 'date-fns'
import AgendamentoService from '@/services/AgendamentoService'
import { rolagemParaElementoPorId } from '@/util/general-util'
import { categoriasAgendamento } from '@/constants/categorias-agendamento'
import { mapGetters } from 'vuex'

export default {
  components: {
    Loading: () => import('@/components/Loading'),
    SelectField: () => import('@/components/SelectField'),
    InputField: () => import('@/components/InputField'),
    Action: () => import('@/components/Action'),
    CheckboxItem: () => import('@/components/CheckboxItem'),
    Alert: () => import('@/components/Alert'),
    ModalHorarioAgendado: () => import('@/components/ModalHorarioAgendado')
  },
  data() {
    return {
      formData: {
        idCategoria: null,
        data: null,
        idServico: null,
        idPostoAtendimento: null,
        cpf: null,
        nomeCompleto: null,
        email: null,
        horario: null
      },
      filtroMeuEndereco: true,
      horarios: [],
      datas: [],
      avisos: [],
      modal: {
        active: false
      },
      isLoadingHorarios: false,
      isLoadingDatas: false,
      isLoadingBloqueadas: false,
      confirmadoId: null,
      enderecoAgendamento: null,
      servicosDesabilitadosUsuario: []
    }
  },

  computed: {
    ...mapGetters(['ehCFC']),
    podeSelecionarTodosPostos () {
      return this.formData.idCategoria !== 4
    },
    usuarioPossuiEnderecos() {
      return this.$store.state.cidades.length
    },
    ciretran() {
      return this.$store.state.ciretran.nome
    },
    isCategoriaHabilitacao() {
      return [1, 3, 4, 5].indexOf(this.formData.idCategoria) !== -1
    },
    categoriasDesabilitadas() {
      let { RETIRADA_DOCUMENTOS } = categoriasAgendamento;
      return [RETIRADA_DOCUMENTOS];
    },
    categorias() {
      return this.$store.state.categorias
        .map(s => {
          return {
            text: s.nome,
            value: s.id,
            disabled: this.categoriasDesabilitadas.indexOf(s.id) !== -1
          }
        })
        .sort((a, b) => (a.id < b.id ? 1 : -1))
    },
    horarioFormatado() {
      if (!this.formData.horario) {
        return null
      }
      return this.formData.horario
        .split(':')
        .splice(0, 2)
        .join(':')
    },
    horariosDisponiveis() {
      return this.horarios.map(h => {
        return { text: h, value: h }
      })
    },
    datasDisponiveis() {
      return this.datas.map(d => d.dia)
    },
    dataSelecionadaDisponivel() {
      let dataSelecionada = this.formData.data;
      return this.datasDisponiveis.some(dataDisponivel => new Date(dataDisponivel.split('-')).toDateString() === dataSelecionada.toDateString());
    },
    dataFormatada() {
      if (this.formData.data) {
        return format(this.formData.data, 'dd/MM/yyyy')
      } else {
        return null
      }
    },
    cpfNormalizado() {
      return this.formData.cpf.replace(/[\\.\-\\/]/gm, '')
    },
    origem() {
      return this.$store.getters.usuarioPredefinido ? 'dd' : 'terminal'
    },
    servicos() {
      return this.$store.state.servicos
        .map(s => {
          return {
            text: s.nome,
            value: s.id,
            podeSelecionarTodosPostos: s.podeSelecionarTodosPostos,
            disabled: this.servicosDesabilitadosUsuario.indexOf(s.id) !== -1 
          }
        })
        .sort((a, b) => (a.text > b.text ? 1 : -1))
    },
    isLoadingPostosDisponiveis() {
      return this.$store.state.carregandoPostosDisponiveis
    },
    postosAtendimento() {
      return this.$store.state.postosDisponiveis
        .map(p => {
          return {
            text: p.nome,
            value: p.id
          }
        })
        .sort((a, b) => (a.text > b.text ? 1 : -1))
    },
    postoAtendimentoSelecionado() {
      return (
        this.postosAtendimento.filter(p => p.value === this.formData.idPostoAtendimento)[0] || {}
      )
    },
    servicoSelecionado() {
      return this.servicos.filter(s => s.value === this.formData.idServico)[0] || {}
    },
    categoriaSelecionada() {
      return this.categorias.filter(c => c.value === this.formData.idCategoria)[0] || {}
    },
    horarioSelecionado() {
      return this.horariosDisponiveis.filter(h => h.value === this.formData.horario)[0] || {}
    },
    agendamentoConfirmado() {
      return {
        nome: this.formData.nomeCompleto,
        cpf: this.formData.cpf,
        categoria: this.categoriaSelecionada.text,
        posto: this.postoAtendimentoSelecionado.text,
        servico: this.servicoSelecionado.text,
        data: this.formData.data,
        horario: this.horarioSelecionado.text,
        id: this.confirmadoId,
        endereco: this.enderecoAgendamento
      }
    },
    mensagemCategoriaSemPosto() {
      if (!this.formData.idCategoria || !this.$store.state.mensagemCategoriaSemPosto) {
        return null
      }
      return this.$store.state.mensagemCategoriaSemPosto[this.formData.idCategoria] || null
    }
  },

  validations: {
    formData: {
      data: {
        required,
        dataFutura,
        limiteDeTrintaDias,
        diaUtil
      },
      nomeCompleto: {
        required
      },
      cpf: {
        required,
        cpf
      },
      horario: {
        required
      },
      idPostoAtendimento: {
        required
      },
      idCategoria: {
        required
      },
      idServico: {
        required
      },
      email: {
        required,
        email
      }
    }
  },
  methods: {
    init() {
      //this.$store.dispatch('setServicos')
      this.$store.dispatch('setCategorias')
      this.carregaBloqueadasEmPeriodo();
    },
    carregaServicos() {
      this.formData.idServico = null
      this.$store
        .dispatch('setServicos', {
          idCategoria: this.formData.idCategoria,
          idPostoAtendimento: this.formData.idPostoAtendimento 
        })
        .then(() => {
          this.isLoadingPostos = false;
        })
        .finally(() => {
          setTimeout(() => this.verificaBloqueados(), 1000);
        })
    },
    carregaPostos() {
      this.formData.idPostoAtendimento = null
      this.isLoadingPostos = true
      this.$store
        .dispatch('setPostosDisponiveis', {
          todas: !this.filtroMeuEndereco && this.podeSelecionarTodosPostos,
          idCidades: this.formData.idCidades,
          idCategoria: this.formData.idCategoria
        })
        .then(() => (this.isLoadingPostos = false))
    },
    carregaBloqueadasEmPeriodo() {
      if(!this.$store.state.user.cpf && this.$v.formData.cpf.$invalid) return
      let CPFParam = this.$store.state.user.cpf ? this.$store.state.user.cpf : this.cpfNormalizado;
      this.limparServicos();
      this.isLoadingBloqueadas = true
      AgendamentoService.listarBloqueioPorCpfEPeriodo(CPFParam)
        .then(resposta => {
          this.servicosDesabilitadosUsuario = [];
          resposta.data.forEach(agendamento => {
            this.servicosDesabilitadosUsuario.push(agendamento.servicoId)
          });
        })
        .catch(erro => {
          this.$error('Erro ao carregar bloqueadas em periodo', erro)
        })
        .finally(() => {
          this.isLoadingBloqueadas = false
        })
    },
    verificaBloqueados() {
      let desabilitado = this.servicos.filter( servico => servico.disabled == true )
      if(desabilitado && desabilitado.length) {
        desabilitado.forEach(servico => {
          let lowerText = servico.text.toLowerCase();
          let capitalizeText = (lowerText && lowerText[0].toUpperCase() + lowerText.slice(1)) || ""
          this.$warn(capitalizeText, 'Opção indisponível por 90 dias para este CPF por não ter comparecido a um agendamento anterior. Caso necessite de atendimento antes deste período, dirija-se presencialmente a uma unidade do Detran.', {duration: -1})
        });
      }
    },
    limparFormulario() {
      this.modal.active = false
      this.confirmadoId = null
      this.enderecoAgendamento = null
      this.formData = {
        idCategoria: null,
        data: new Date(),
        idServico: null,
        horario: null,
        idPostoAtendimento: null,
        cpf: this.$store.state.user.cpf || null,
        nomeCompleto: this.$store.state.user.nome || null,
        email: (typeof this.$store.state.user.email === 'string' ? this.$store.state.user.email.trim() : this.$store.state.user.email) || null
      }
      this.horarios = []
    },
    limparServicos() {
      this.servicosDesabilitadosUsuario = [];
      this.formData.idPostoAtendimento = null;
      this.formData.idServico = null;
    },
    onAlteracaoCategoria() {
      let data = {
        ...this.formData,
        idServico: null,
        idPostoAtendimento: null,
        data: null,
        horario: null
      }
      this.horarios = []
      this.formData = data
    },
    onAlteracaoPosto() {
      let data = { ...this.formData, data: null, horario: null }
      this.horarios = []
      this.formData = data
      this.buscarDatasDisponiveis()
    },
    clone() {
      let clone = Object.assign({}, this.formData)
      return Object.assign(clone, {
        data: this.dataFormatada,
        origem: this.origem,
        cpf: this.cpfNormalizado,
        horario: this.horarioFormatado
      })
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const data = this.clone()

      AgendamentoService.confirmar(data)
        .then(({ data }) => {
          this.confirmadoId = data.id
          this.enderecoAgendamento = (data.postoAtendimento || {}).endereco
          this.modal.active = true
        })
        .catch(erro => {
          if (erro.response && erro.response.status == 404) {
            this.$warn('Aviso', erro)
          } else {
            this.$error('Ops...', erro)
          }
        })
    },
    buscarDatasDisponiveis() {
      this.$v.$touch()
      if (!this.formData.idPostoAtendimento) {
        return
      }
      if (!this.formData.idServico) {
        return
      }

      this.isLoadingDatas = true
      AgendamentoService.buscarDatasDisponiveis(
        this.formData.idPostoAtendimento,
        this.formData.idServico
      )
        .then(({ data }) => {
          this.datas = data.datas || data
          this.avisos = data.avisos || []
          // Calendário do Datepicker
          if (!this.datas.length) {
            return
          }
          const datePickerCalendar = this.$refs.calendario.$refs.calendar

          // Mover para o mês que tem dias disponíveis
          // Pra desconsiderar timezone precisou o 00:00:00
          // Meio segundo pra dar tempo da rolagem chegar no ponto
          setTimeout(() => {
            datePickerCalendar.move((data.datas || data)[0].dia + ' 00:00:00');
            this.selecionarData();
          }, 500)
        })
        .catch(erro => {
          this.datas = []
          this.horarios = []
          if (erro.response && erro.response.status == 404) {
            this.$warn('Aviso', erro)
          } else {
            this.$error('Ops...', erro)
          }
        })
        .finally(() => {
          this.isLoadingDatas = false
          rolagemParaElementoPorId('bloco-posto-atendimento')
        })
    },
    selecionarData() {
      this.formData.horario = null
      this.preAgendar()
    },
    preAgendar() {
      this.$v.$touch()
      if (!this.formData.idCategoria) {
        return
      }
      if (!this.formData.data) {
        return
      }
      if (!this.dataSelecionadaDisponivel) {
        return
      }
      const data = this.clone()
      this.isLoadingHorarios = true
      AgendamentoService.reservar(data)
        .then(({ data }) => {
          this.horarios = data.horariosDisponiveis
          this.formData.horario = data.horarioAgendado
        })
        .catch(erro => {
          this.horarios = []
          if (erro.response && erro.response.status == 404) {
            this.$warn('Aviso', erro)
          } else {
            this.$error('Ops...', erro)
          }
        })
        .finally(() => {
          this.isLoadingHorarios = false
          rolagemParaElementoPorId('bloco-data-atendimento')
        })
    }
  },
  mounted() {
    this.init()
    this.limparFormulario()
  }
}
</script>
<style scoped>
.alert {
  margin: auto;
}
.container {
  transition: all 0.3s ease-in-out 0s;
}

@media print {
  .container {
    display: none;
  }
}

.is-loading {
  opacity: 0.15;
}

.calendar-label {
  color: rgba(45, 82, 160, 0.7);
  font-size: 1.2em;
  margin-bottom: 15px;
  display: block;
}

.actions-container {
  display: flex;
  justify-content: center;
}

.calendar-wrapper {
  display: flex;
  justify-content: center;
}

.calendar-container {
  flex: 1 1 auto;
  max-width: 375px;
}

.vc-container {
  --day-min-height: 34px;
  --day-content-width: 34px;
  --day-content-height: 34px;
  --highlight-height: 34px;
  font-family: 'Lato', sans-serif;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-color: #f7fafc;
}
.horario {
  align-items: center;
  justify-content: center;
  display: flex;
}

.horario .form-item {
  max-width: 375px;
  flex: 1 1 auto;
}
</style>
<style>
.calendar-container .vc-title {
  text-transform: uppercase;
}
.agendamento .form-select-dropdown {
  max-height: 242px;
}
</style>
